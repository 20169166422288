// organizations.js
import axios from 'axios';

const API_BASE_URL = 'http://146.190.32.177:5002';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const getOrganizationMembers = async (organizationId) => {
  const response = await api.get(`/api/organizations/${organizationId}/members`);
  return response.data;
};

export const getApprovedEmails = async (organizationId) => {
  const response = await api.get(`/api/organizations/${organizationId}/approved-emails`);
  return response.data;
};

export const removeApprovedEmail = async (organizationId, emailId) => {
  const response = await api.delete(`/api/organizations/${organizationId}/approved-emails/${emailId}`);
  return response.data;
};

export const addApprovedEmail = async (organizationId, email) => {
  const response = await api.post(`/api/organizations/${organizationId}/approved-emails`, { email });
  return response.data;
};
