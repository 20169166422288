import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { getOrganizationMembers, getApprovedEmails, addApprovedEmail, removeApprovedEmail } from '../../../services/api/organizations';
import { Users, Mail, Plus, X } from 'lucide-react';

const OrganizationManagement = () => {
  const [members, setMembers] = useState([]);
  const [approvedEmails, setApprovedEmails] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  const fetchMembers = useCallback(async () => {
    console.log('Fetching members, user:', user);
    if (!user || !user.OrganizationId) {
      console.error('User or OrganizationId is not available');
      setError('Unable to fetch organization members. Please try again later.');
      return;
    }
    try {
      const data = await getOrganizationMembers(user.OrganizationId);
      console.log('Received members data:', data);
      setMembers(data);
    } catch (error) {
      console.error('Failed to fetch members', error);
      setError('Failed to fetch organization members. Please try again.');
    }
  }, [user]);

  const fetchApprovedEmails = useCallback(async () => {
    if (!user || !user.OrganizationId) {
      console.error('User or OrganizationId is not available');
      setError('Unable to fetch approved emails. Please try again later.');
      return;
    }
    try {
      setIsLoading(true);
      const data = await getApprovedEmails(user.OrganizationId);
      setApprovedEmails(data);
      setError('');
    } catch (error) {
      console.error('Failed to fetch approved emails', error);
      setError('Failed to fetch approved emails. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchMembers();
    fetchApprovedEmails();
  }, [fetchMembers, fetchApprovedEmails]);

  const handleAddEmail = async (e) => {
    e.preventDefault();
    if (!user || !user.OrganizationId) {
      setError('Unable to add email. Please try again later.');
      return;
    }
    setError('');
    setSuccess('');
    try {
      await addApprovedEmail(user.OrganizationId, newEmail);
      setNewEmail('');
      setSuccess('Email added to approval list successfully!');
      fetchApprovedEmails();
    } catch (error) {
      console.error('Failed to add email', error);
      setError('Failed to add email. Please try again.');
    }
  };

  const handleRemoveEmail = async (emailId) => {
    if (!user || !user.OrganizationId) {
      setError('Unable to remove email. Please try again later.');
      return;
    }
    try {
      await removeApprovedEmail(user.OrganizationId, emailId);
      setSuccess('Email removed from approval list successfully!');
      fetchApprovedEmails();
    } catch (error) {
      console.error('Failed to remove email', error);
      setError('Failed to remove email. Please try again.');
    }
  };

  if (isLoading) {
    return <div className="text-white">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3xl font-bold mb-6">Organization: {user?.Organization?.name || 'Loading...'}</h2>
        
        {/* Members list */}
        <div className="bg-gray-800 rounded-lg p-6 mb-8">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <Users className="mr-2" /> Members
          </h3>
          {members.length === 0 ? (
            <p>No members found.</p>
          ) : (
            <ul className="space-y-2">
              {members.map(member => (
                <li key={member.id} className="flex items-center justify-between bg-gray-700 rounded p-2">
                  <div className="flex items-center">
                    <Mail className="mr-2 h-4 w-4" />
                    <span>{member.username} ({member.email})</span>
                  </div>
                  {member.isCreator && (
                    <span className="text-xs bg-blue-500 text-white px-2 py-1 rounded">Creator</span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
        
        {/* Approved Emails list */}
        <div className="bg-gray-800 rounded-lg p-6 mb-8">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <Mail className="mr-2" /> Approved Emails
          </h3>
          {approvedEmails.length === 0 ? (
            <p>No approved emails yet.</p>
          ) : (
            <ul className="space-y-2">
              {approvedEmails.map(email => (
                <li key={email.id} className="flex items-center justify-between bg-gray-700 rounded p-2">
                  <span>{email.email}</span>
                  <button onClick={() => handleRemoveEmail(email.id)} className="text-red-500 hover:text-red-700">
                    <X className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
        
        {/* Add Approved Email form */}
        <form onSubmit={handleAddEmail} className="bg-gray-800 rounded-lg p-6">
          <h3 className="text-xl font-semibold mb-4">Add Approved Email</h3>
          <div className="flex items-center mb-4">
            <Mail className="h-5 w-5 text-gray-400 mr-2" />
            <input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="Enter email to approve"
              required
              className="flex-grow appearance-none rounded-l-md px-3 py-2 border border-gray-700 placeholder-gray-500 text-white bg-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button 
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <Plus className="h-5 w-5 mr-2" /> Add
            </button>
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          {success && <p className="text-green-500 text-sm">{success}</p>}
        </form>
      </div>
    </div>
  );
};

export default OrganizationManagement;
