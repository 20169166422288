import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchBinsWithItemCount, createBin, deleteBin } from '../../../services/api/items';
import { Plus, Trash2, Package, Loader } from 'lucide-react';
import ConfirmationModal from '../../common/ConfirmationModal';

export const ManageBins = () => {
  const [bins, setBins] = useState([]);
  const [newBinName, setNewBinName] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [binToDelete, setBinToDelete] = useState(null);

  useEffect(() => {
    loadBins();
  }, []);

  const loadBins = async () => {
    try {
      setLoading(true);
      const binsData = await fetchBinsWithItemCount();
      setBins(binsData);
      setError(null);
    } catch (err) {
      setError('Failed to load bins. Please try again.');
      console.error('Error loading bins:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateBin = async (e) => {
    e.preventDefault();
    try {
      await createBin({ name: newBinName });
      setNewBinName('');
      loadBins();
    } catch (err) {
      setError(err.message || 'Failed to create bin. Please try again.');
    }
  };

  const handleDeleteClick = (bin) => {
    setBinToDelete(bin);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (binToDelete) {
      try {
        await deleteBin(binToDelete.id);
        loadBins();
        setIsDeleteModalOpen(false);
        setBinToDelete(null);
      } catch (err) {
        setError('Failed to delete bin. It might not be empty.');
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900 text-white">
        <Loader className="animate-spin mr-2" size={24} />
        <span>Loading bins...</span>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4 bg-gray-900 text-white">
      <h2 className="text-2xl font-bold mb-4">Manage Bins</h2>
      <form onSubmit={handleCreateBin} className="mb-6">
        <div className="flex items-center">
          <input
            className="flex-grow mr-2 p-2 bg-gray-800 border border-gray-700 rounded text-white"
            type="text"
            placeholder="New bin name"
            value={newBinName}
            onChange={(e) => setNewBinName(e.target.value)}
            required
          />
          <button
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded text-white transition duration-200 flex items-center"
            type="submit"
          >
            <Plus size={20} className="mr-2" />
            Add Bin
          </button>
        </div>
      </form>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {bins.map(bin => (
          <div key={bin.id} className="bg-gray-800 p-4 rounded-lg shadow">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-semibold">{bin.name}</h3>
              <Link 
                to={`/search?bin=${bin.id}`}
                className="text-sm text-blue-400 hover:text-blue-300 flex items-center"
              >
                <Package size={16} className="mr-1" />
                {bin.itemCount} items
              </Link>
            </div>
            <button
              onClick={() => handleDeleteClick(bin)}
              disabled={bin.itemCount > 0}
              className={`w-full mt-2 px-4 py-2 rounded text-white transition duration-200 flex items-center justify-center ${
                bin.itemCount > 0
                  ? 'bg-gray-600 cursor-not-allowed'
                  : 'bg-red-600 hover:bg-red-700'
              }`}
            >
              <Trash2 size={20} className="mr-2" />
              {bin.itemCount > 0 ? 'Cannot Delete' : 'Delete'}
            </button>
          </div>
        ))}
      </div>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
        message="Are you sure you want to delete this bin?"
      />
    </div>
  );
};
