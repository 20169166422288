import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchParts, updatePart, deletePart, fetchBins } from '../../../services/api/items';
import { Pencil, Trash2, Package, Search, Plus, Minus, Save, X, Loader } from 'lucide-react';
import ConfirmationModal from '../../common/ConfirmationModal';

export const SearchParts = () => {
  const [parts, setParts] = useState([]);
  const [bins, setBins] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBin, setSelectedBin] = useState('');
  const [error, setError] = useState(null);
  const [editingPart, setEditingPart] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [partToDelete, setPartToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    loadData();
    const params = new URLSearchParams(location.search);
    const binFilter = params.get('bin');
    if (binFilter) {
      setSelectedBin(binFilter);
    }
  }, [location]);

  const loadData = async () => {
    setLoading(true);
    try {
      const [partsData, binsData] = await Promise.all([fetchParts(), fetchBins()]);
      setParts(partsData);
      setBins(binsData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = async (id, change) => {
    const part = parts.find(p => p.id === id);
    if (part) {
      const newQuantity = part.quantity + change;
      if (newQuantity >= 0) {
        try {
          const updatedPart = await updatePart(id, { ...part, quantity: newQuantity });
          setParts(prevParts => 
            prevParts.map(p => p.id === id ? updatedPart : p)
          );
        } catch (err) {
          setError(err.message);
        }
      }
    }
  };

  const handleDeleteClick = (part) => {
    setPartToDelete(part);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (partToDelete) {
      try {
        await deletePart(partToDelete.id);
        setParts(prevParts => prevParts.filter(p => p.id !== partToDelete.id));
        setIsDeleteModalOpen(false);
        setPartToDelete(null);
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const handleBinChange = (e) => {
    const binId = e.target.value;
    setSelectedBin(binId);
    navigate(binId ? `?bin=${binId}` : '');
  };

  const handleEditPart = (part) => {
    setEditingPart({ ...part });
  };

  const handleSavePart = async () => {
    try {
      const updatedPart = await updatePart(editingPart.id, editingPart);
      setParts(prevParts =>
        prevParts.map(p => p.id === updatedPart.id ? updatedPart : p)
      );
      setEditingPart(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCancelEdit = () => {
    setEditingPart(null);
  };

  const filteredParts = parts.filter(part => 
    (part.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    part.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (selectedBin ? part.BinId === parseInt(selectedBin) : true)
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900 text-white">
        <Loader className="animate-spin mr-2" size={24} />
        <span>Loading parts...</span>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4 bg-gray-900 text-white">
      <h2 className="text-2xl font-bold mb-4">Search Parts</h2>
      <div className="flex flex-col sm:flex-row mb-4 space-y-2 sm:space-y-0 sm:space-x-2">
        <div className="relative flex-grow">
          <input
            className="w-full p-2 pl-10 bg-gray-800 border border-gray-700 rounded text-white"
            type="text"
            placeholder="Search parts..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>
        <select
          className="w-full sm:w-auto p-2 bg-gray-800 border border-gray-700 rounded text-white"
          value={selectedBin}
          onChange={handleBinChange}
        >
          <option value="">All Bins</option>
          {bins.map(bin => (
            <option key={bin.id} value={bin.id}>{bin.name}</option>
          ))}
        </select>
      </div>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="space-y-4">
        {filteredParts.map(part => (
          <div key={part.id} className="bg-gray-800 p-4 rounded-lg shadow">
            {editingPart && editingPart.id === part.id ? (
              <div>
                <input
                  className="w-full p-2 mb-2 bg-gray-700 border border-gray-600 rounded text-white"
                  value={editingPart.name}
                  onChange={(e) => setEditingPart({...editingPart, name: e.target.value})}
                  maxLength={50}
                />
                <textarea
                  className="w-full p-2 mb-2 bg-gray-700 border border-gray-600 rounded text-white"
                  value={editingPart.description}
                  onChange={(e) => setEditingPart({...editingPart, description: e.target.value})}
                  maxLength={200}
                />
                <select
                  className="w-full p-2 mb-2 bg-gray-700 border border-gray-600 rounded text-white"
                  value={editingPart.BinId || ''}
                  onChange={(e) => setEditingPart({...editingPart, BinId: e.target.value})}
                >
                  <option value="">No Bin</option>
                  {bins.map(bin => (
                    <option key={bin.id} value={bin.id}>{bin.name}</option>
                  ))}
                </select>
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={handleSavePart}
                    className="px-4 py-2 bg-green-600 hover:bg-green-700 rounded text-white transition duration-200 flex items-center"
                  >
                    <Save size={20} className="mr-2" />
                    Save
                  </button>
                  <button
                    onClick={handleCancelEdit}
                    className="px-4 py-2 bg-gray-600 hover:bg-gray-700 rounded text-white transition duration-200 flex items-center"
                  >
                    <X size={20} className="mr-2" />
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-2">
                  <h3 className="text-lg font-semibold mb-1 sm:mb-0">{part.name}</h3>
                  <span className="text-sm text-gray-400 flex items-center">
                    <Package size={16} className="mr-1" />
                    Bin: {part.Bin && part.Bin.name ? part.Bin.name : 'N/A'}
                  </span>
                </div>
                <div className="flex items-center mb-2">
                  <button
                    onClick={() => handleQuantityChange(part.id, -1)}
                    className="w-8 h-8 flex items-center justify-center rounded-full border-2 border-white text-white font-bold hover:bg-white hover:text-gray-800 transition duration-200"
                  >
                    <Minus size={16} />
                  </button>
                  <span className="mx-4 font-semibold">{part.quantity}</span>
                  <button
                    onClick={() => handleQuantityChange(part.id, 1)}
                    className="w-8 h-8 flex items-center justify-center rounded-full border-2 border-white text-white font-bold hover:bg-white hover:text-gray-800 transition duration-200"
                  >
                    <Plus size={16} />
                  </button>
                </div>
                <p className="text-gray-400 mb-2 line-clamp-2 hover:line-clamp-none transition-all duration-300">{part.description}</p>
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => handleEditPart(part)}
                    className="p-2 text-white hover:text-blue-400 transition duration-200"
                    aria-label="Edit part"
                  >
                    <Pencil size={20} />
                  </button>
                  <button
                    onClick={() => handleDeleteClick(part)}
                    className="p-2 text-white hover:text-red-400 transition duration-200"
                    aria-label="Delete part"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
        message="Are you sure you want to delete this part?"
      />
    </div>
  );
};
