import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { login as apiLogin, signup as apiSignup, fetchUserProfile } from '../services/api/users';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const checkAuthStatus = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const userData = await fetchUserProfile();
        setUser(userData);
      } catch (error) {
        console.error('Auth check failed:', error);
        logout();
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const login = async (email, password) => {
    try {
      setError(null);
      const response = await apiLogin(email, password);
      if (response && response.token) {
        localStorage.setItem('token', response.token);
        const userData = await fetchUserProfile();
        setUser(userData);
        return true;
      }
      setError('Invalid login response');
      return false;
    } catch (error) {
      console.error('Login failed:', error);
      setError(error.response?.data?.message || 'Login failed. Please try again.');
      return false;
    }
  };

  const signup = async (username, email, password, organizationName) => {
    try {
      setError(null);
      const response = await apiSignup(username, email, password, organizationName);
      if (response && response.token) {
        localStorage.setItem('token', response.token);
        const userData = await fetchUserProfile();
        setUser(userData);
        return true;
      }
      setError('Invalid signup response');
      return false;
    } catch (error) {
      console.error('Signup failed:', error);
      setError(error.response?.data?.message || 'Signup failed. Please try again.');
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loading, error, login, logout, signup }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
