import React from 'react';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70 backdrop-blur-md z-50">
      <div className="relative w-96 p-6 bg-white bg-opacity-10 border border-gray-200 border-opacity-30 rounded-lg shadow-lg backdrop-filter backdrop-blur-md">
        <div className="text-center">
          <h3 className="text-lg font-semibold text-white">{message}</h3>
          <div className="mt-3 text-sm text-gray-300">
            Are you sure you want to perform this action?
          </div>
          <div className="mt-6 flex justify-center space-x-4">
            <button
              className="px-4 py-2 bg-red-600 text-white font-medium rounded-lg shadow-md hover:bg-red-700 transition duration-200"
              onClick={onConfirm}
            >
              Yes
            </button>
            <button
              className="px-4 py-2 bg-gray-600 text-white font-medium rounded-lg shadow-md hover:bg-gray-700 transition duration-200"
              onClick={onClose}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
