import React, { useState, useEffect } from 'react';
import { fetchBins, createPart, fetchPartSuggestions } from '../../../services/api/items';
import ConfirmationModal from '../../common/ConfirmationModal';
import { useAuth } from '../../../context/AuthContext';

export const AllocateParts = () => {
  const [bins, setBins] = useState([]);
  const [newPart, setNewPart] = useState({ name: '', quantity: 0, description: '', BinId: '' });
  const [error, setError] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    loadBins();
  }, []);

  const loadBins = async () => {
    try {
      const data = await fetchBins();
      setBins(data);
    } catch (err) {
      setError('Failed to load bins. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPart(prev => ({
      ...prev,
      [name]: name === 'quantity' ? parseInt(value, 10) : value
    }));

    if (name === 'name') {
      fetchSuggestions(value);
    }
  };

  const fetchSuggestions = async (searchTerm) => {
    if (searchTerm.length < 2) {
      setSuggestions([]);
      return;
    }

    setIsLoading(true);
    try {
      console.log('Fetching suggestions for:', searchTerm);
      const data = await fetchPartSuggestions(searchTerm);
      console.log('Received suggestions:', data);
      setSuggestions(data);
    } catch (err) {
      console.error('Failed to fetch suggestions:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setNewPart(prev => ({
      ...prev,
      name: suggestion.name,
      description: suggestion.description,
      BinId: suggestion.binId
    }));
    setSuggestions([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsConfirmModalOpen(true);
  };

  const handleConfirmAdd = async () => {
    try {
      const partWithOrganization = {
        ...newPart,
        OrganizationId: user.organizationId
      };
      await createPart(partWithOrganization);
      setNewPart({ name: '', quantity: 0, description: '', BinId: '' });
      setError(null);
      setIsConfirmModalOpen(false);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to create part. Please try again.');
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4 bg-gray-900 text-white">
      <h2 className="text-2xl font-bold mb-4">Allocate New Part</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="relative">
          <label className="block text-sm font-medium mb-1" htmlFor="name">Part Name</label>
          <input
            className="w-full p-2 bg-gray-800 border border-gray-700 rounded text-white"
            id="name"
            type="text"
            name="name"
            value={newPart.name}
            onChange={handleInputChange}
            required
            maxLength={50}
          />
          <span className="text-sm text-gray-400">{newPart.name.length}/50 characters</span>
          {isLoading && <div className="absolute right-2 top-2">Loading...</div>}
          {suggestions.length > 0 && (
            <ul className="absolute z-10 w-full bg-gray-800 border border-gray-700 rounded mt-1 max-h-60 overflow-auto">
              {suggestions.map((suggestion) => (
                <li
                  key={suggestion.id}
                  className="p-2 hover:bg-gray-700 cursor-pointer"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.name} - {suggestion.binName}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium mb-1" htmlFor="quantity">Quantity</label>
          <input
            className="w-full p-2 bg-gray-800 border border-gray-700 rounded text-white"
            id="quantity"
            type="number"
            name="quantity"
            value={newPart.quantity}
            onChange={handleInputChange}
            required
            min="0"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1" htmlFor="description">Description</label>
          <textarea
            className="w-full p-2 bg-gray-800 border border-gray-700 rounded text-white"
            id="description"
            name="description"
            value={newPart.description}
            onChange={handleInputChange}
            rows="3"
            maxLength={200}
          />
          <span className="text-sm text-gray-400">{newPart.description.length}/200 characters</span>
        </div>
        <div>
          <label className="block text-sm font-medium mb-1" htmlFor="BinId">Select Bin</label>
          <select
            className="w-full p-2 bg-gray-800 border border-gray-700 rounded text-white"
            id="BinId"
            name="BinId"
            value={newPart.BinId}
            onChange={handleInputChange}
            required
          >
            <option value="">Select a Bin</option>
            {bins.map(bin => (
              <option key={bin.id} value={bin.id}>{bin.name}</option>
            ))}
          </select>
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <button
          className="w-full px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded text-white transition duration-200"
          type="submit"
        >
          Add Part
        </button>
      </form>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={handleConfirmAdd}
        message={`Are you sure you want to add ${newPart.quantity} ${newPart.name}(s) to ${bins.find(b => b.id === parseInt(newPart.BinId))?.name || 'the selected bin'}?`}
      />
    </div>
  );
};

