import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ManageBins } from './components/features/inventory/ManageBins';
import { SearchParts } from './components/features/inventory/SearchParts';
import { AllocateParts } from './components/features/inventory/AllocateParts';
import LoginPage from './components/pages/LoginPage';
import SignUp from './components/features/auth/SignUp';
import OrganizationManagement from './components/features/organization/OrganizationManagement';
import { Navigation } from './components/layout/Navigation';
import { AuthProvider, useAuth } from './context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const token = localStorage.getItem('token');
  
  if (loading) {
    return <div className="flex justify-center items-center h-screen bg-gray-900 text-white">Loading...</div>;
  }

  if (!user && !token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App bg-gray-900 min-h-screen text-white flex flex-col">
          <Navigation />
          <div className="flex-grow container mx-auto">
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/manage-bins" element={<ProtectedRoute><ManageBins /></ProtectedRoute>} />
              <Route path="/search" element={<ProtectedRoute><SearchParts /></ProtectedRoute>} />
              <Route path="/allocate" element={<ProtectedRoute><AllocateParts /></ProtectedRoute>} />
              <Route path="/organization" element={<ProtectedRoute><OrganizationManagement /></ProtectedRoute>} />
              <Route path="/" element={<ProtectedRoute><ManageBins /></ProtectedRoute>} />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
