// items.js
import axios from 'axios';

const API_BASE_URL = 'http://146.190.32.177:5002';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const fetchBinsWithItemCount = async () => {
  const response = await api.get('/api/bins?includeItemCount=true');
  return response.data;
};

export const createBin = async (binData) => {
  const response = await api.post('/api/bins', binData);
  return response.data;
};

export const deleteBin = async (id) => {
  const response = await api.delete(`/api/bins/${id}`);
  return response.data;
};

export const fetchParts = async () => {
  const response = await api.get('/api/items?includeBin=true');
  return response.data;
};

export const createPart = async (partData) => {
  const response = await api.post('/api/items', partData);
  return response.data;
};

export const updatePart = async (id, partData) => {
  const response = await api.put(`/api/items/${id}`, partData);
  return response.data;
};

export const deletePart = async (id) => {
  const response = await api.delete(`/api/items/${id}`);
  return response.data;
};

export const fetchBins = async () => {
  const response = await api.get('/api/bins');
  return response.data;
};

export const fetchPartSuggestions = async (searchTerm) => {
  const response = await api.get(`/api/items/suggestions?term=${encodeURIComponent(searchTerm)}`);
  return response.data;
};
